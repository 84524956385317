import { config } from "@/config";
import { Config, ConfigDataType } from "@/models/Config";
import { ContentSchema } from "@/models/ContentSchema";
import { ControlPanel } from "@/models/ControlPanel";
import { DataRes } from "@/models/DataRes";
import { request, requestBlob } from "./BaseService";
import { ConfigModel } from "@/models/ConfigModel";
import { ConfigClone } from "@/models/ConfigClone";
import { ConfigListPageDto } from "@/models/ConfigListPageDto";
import { ConfigDto } from "@/models/ConfigDto";
import { ConfigDevtoolResponseDto } from "@/models/ConfigDevtoolResponseDto";
import { toastAdd } from "@/components/toasts/toastHelper";
import { ToastType } from "@/models/Toast";

// This is not being used. All methods using getConfigVersionsDtoPage
// Returns list of all versions of a particular config.
// export const getConfigVersions = async (productContentId: string, envContentId: string, contentId: string): Promise<DataRes<Config[]>> => {
//   const configs = await request<Config[]>(`/product/${productContentId}/env/${envContentId}/config/${contentId}`);
//   configs.data = configs.data.map((x: Config) => new Config(x)).sort((x, y) => y.version - x.version);
//   return configs;
// };

// This is not being used. All methods using getConfigVersionsDtoPage
// export const getConfigVersionsPage = async (productContentId: string, envContentId: string, contentId: string, page: number, pageSize: number): Promise<DataRes<ConfigListPageModel>> => {
//   const configs = await request<ConfigListPageModel>(`/product/${productContentId}/env/${envContentId}/config/${contentId}/page?page=${page}&pageSize=${pageSize}`);
//   configs.data.page = configs.data.page.map((x: Config) => new Config(x)).sort((x, y) => y.version - x.version);
//   return configs;
// };

export const getConfigVersionsDtoPage = async (
  productContentId: string,
  envContentId: string,
  contentId: string,
  page: number,
  pageSize: number
): Promise<DataRes<ConfigListPageDto>> => {
  const configsDataRes = await request<ConfigListPageDto>(
    `/product/${productContentId}/env/${envContentId}/config/${contentId}/pageDto?page=${page}&pageSize=${pageSize}`
  );
  configsDataRes.data = new ConfigListPageDto(configsDataRes.data);
  configsDataRes.data.page = configsDataRes.data.page
    .map((x: ConfigDto) => new ConfigDto(x))
    .sort((x, y) => y.version - x.version);

  return configsDataRes;
};

export const getConfig = async (
  productContentId: string,
  envContentId: string,
  contentId: string
): Promise<DataRes<ConfigDto>> => {
  const data = await request<ConfigDto>(`/product/${productContentId}/env/${envContentId}/config/${contentId}/latest`);
  data.data = new ConfigDto(data.data);
  return data;
};

export const getConfigSilent = async (
  productContentId: string,
  envContentId: string,
  contentId: string
): Promise<DataRes<ConfigModel>> => {
  const data = await request<ConfigModel>(
    `/product/${productContentId}/env/${envContentId}/config/${contentId}/latest`,
    {},
    { noPropagate: true }
  );
  data.data = new ConfigModel(data.data);
  return data;
};

export const getConfigVersion = async (
  productContentId: string,
  envContentId: string,
  contentId: string,
  version: number
): Promise<DataRes<Config>> => {
  const data = await request<Config>(
    `/product/${productContentId}/env/${envContentId}/config/${contentId}/${version.toString()}`
  );
  data.data = new Config(data.data);
  return data;
};

// eslint-disable-next-line
export const downloadEnvironment = async (productContentId: string, envContentId: string, type: string) => {
  const response = await requestBlob(`/product/${productContentId}/env/${envContentId}/download/${type}`);
  if (response.size > 0) {
    return new Blob([response], { type: "zip application/zip, application/octet-stream" });
  }
  return undefined;
};

export const archiveEnvironment = async (
  productContentId: string,
  envContentId: string,
  password: string
): Promise<DataRes<boolean>> => {
  return await request<boolean>(`/product/${productContentId}/env/${envContentId}/archive`, {
    method: "PUT",
    body: JSON.stringify({
      password,
    }),
  });
};
export const saveConfig = async (
  productContentId: string,
  envContentId: string,
  data: Config
): Promise<DataRes<Config>> => {
  const result = await request<Config>(`/product/${productContentId}/env/${envContentId}/config/${data.contentId}`, {
    method: "POST",
    body: JSON.stringify(data),
  });
  result.data = new Config(result.data);
  return result;
};

export const saveConfigDto = async (
  productContentId: string,
  envContentId: string,
  data: ConfigDto
): Promise<DataRes<Config>> => {
  const result = await request<Config>(`/product/${productContentId}/env/${envContentId}/config/${data.contentId}`, {
    method: "POST",
    body: JSON.stringify(data),
  });
  result.data = new Config(result.data);
  return result;
};

export const archiveConfigVersions = async (
  productContentId: string,
  envContentId: string,
  configContentId: string,
  versionsNumbers: Array<number>
): Promise<DataRes<boolean>> => {
  const result = await request<boolean>(
    `/product/${productContentId}/env/${envContentId}/config/${configContentId}/archive`,
    {
      method: "POST",
      body: JSON.stringify(versionsNumbers),
    }
  );
  return result;
};

export const removeConfigs = async (
  productContentId: string,
  envContentId: string,
  contentIds: string[]
): Promise<DataRes<unknown>> => {
  const queryString = contentIds.map((contentId) => `cIds=${contentId}`).join("&");
  const result = request(`/product/${productContentId}/env/${envContentId}/configs?${queryString}`, {
    method: "DELETE",
  });
  return result;
};

// eslint-disable-next-line
export const getDynamicConfig = async (
  params: { [key: string]: unknown },
  contentId: string,
  userId: string,
  apiKey: string
) => {
  // eslint-disable-next-line
  return request<{ data: unknown }>(
    `/config/${contentId}/dynamic`,
    {
      method: "POST",
      body: JSON.stringify({
        u: userId,
        p: params,
      }),
    },
    {
      customEndpoint: config.apiEndpoint,
      headers: {
        "X-Api-Key": apiKey,
      },
    }
  );
};

// There is a noPropagate here specifically to suppress the 404 message that pops up when attempting to access the control panel of a config that does not yet have a CP attached.
// @TODO: May be a better way? Since it will suppress all other errors with this endpoint from showing on client as a toast.
export const getControlPanel = async (
  productContentId: string,
  envContentId: string,
  contentId: string
): Promise<DataRes<ControlPanel>> => {
  const data = await request<ControlPanel>(
    `/product/${productContentId}/env/${envContentId}/config/${contentId}/control-panel`,
    undefined,
    { noPropagate: true }
  );
  data.data = new ControlPanel(data.data);
  return data;
};

export const saveControlPanel = async (
  productContentId: string,
  envContentId: string,
  configContentId: string,
  data: string
): Promise<DataRes<ControlPanel>> => {
  const result = await request<ControlPanel>(
    `/product/${productContentId}/env/${envContentId}/config/${configContentId}/control-panel`,
    {
      method: "POST",
      body: JSON.stringify({ schema: data }),
    }
  );
  result.data = new ControlPanel(result.data);
  return result;
};

export const moveConfigs = async (
  productContentId: string,
  envContentId: string,
  configContentIds: string[],
  destFolderHid: string
): Promise<DataRes<boolean>> => {
  const queryString = configContentIds.map((contentId) => `cIds=${contentId}`).join("&");
  return await request<boolean>(`/product/${productContentId}/env/${envContentId}/configs/move?${queryString}`, {
    method: "PUT",
    body: JSON.stringify(destFolderHid),
  });
};

export const cloneConfig = async (
  productContentId: string,
  envContentId: string,
  configContentId: string,
  configBody: ConfigClone
): Promise<DataRes<boolean>> => {
  return await request<boolean>(`/product/${productContentId}/env/${envContentId}/config/${configContentId}/clone`, {
    method: "POST",
    body: JSON.stringify(configBody),
  });
};

// Note: This is not being used anymore after ConfigPush was migrated to use the SyncEnv API on 0.14.11
export const pushConfig = async (
  productContentId: string,
  envContentId: string,
  configContentId: string,
  targetEnvContentId: string,
  targetFolderHid = "",
  configVersion = 0
): Promise<DataRes<boolean>> => {
  const result = await request<boolean>(
    `/product/${productContentId}/env/${envContentId}/config/${configContentId}/push`,
    {
      method: "POST",
      body: JSON.stringify({
        targetEnvironmentContentId: targetEnvContentId,
        targetFolderHid: targetFolderHid,
        configVersion: configVersion,
      }),
    }
  );
  return result;
};

export const getSchema = async (
  productContentId: string,
  envContentId: string,
  contentId: string
): Promise<DataRes<ContentSchema>> => {
  // noPropagate to suppress the 404 toast when a schema is not found.
  const data = await request<ContentSchema>(
    `/product/${productContentId}/env/${envContentId}/config/${contentId}/schema`,
    undefined,
    { noPropagate: true }
  );
  data.data = new ContentSchema(data.data);
  return data;
};

export const attachSchema = async (
  productContentId: string,
  envContentId: string,
  contentId: string,
  schemaId: number
): Promise<DataRes<boolean>> => {
  return await request<boolean>(
    `/product/${productContentId}/env/${envContentId}/config/${contentId}/schema/${schemaId}`,
    {
      method: "POST",
    }
  );
};

export const detachSchema = async (
  productContentId: string,
  envContentId: string,
  contentId: string,
  schemaId: number
): Promise<DataRes<boolean>> => {
  return await request<boolean>(
    `/product/${productContentId}/env/${envContentId}/config/${contentId}/schema/${schemaId}`,
    {
      method: "DELETE",
    }
  );
};

type ListEnvConfigIdsParams = {
  productContentId: string;
  envContentId: string;
};

export const listEnvConfigIds = async ({
  productContentId,
  envContentId,
}: ListEnvConfigIdsParams): Promise<DataRes<string[]>> => {
  const configs = await request<string[]>(`/product/${productContentId}/env/${envContentId}/config/content-ids`);
  return configs;
};

export const getTypescriptInterface = async (
  productContentId: string,
  envContentId: string,
  contentId: string
): Promise<DataRes<ConfigDevtoolResponseDto>> => {
  return await request<ConfigDevtoolResponseDto>(
    `/product/${productContentId}/env/${envContentId}/config/${contentId}/generated/typescript-interface`
  );
};

export const getCsharpClass = async (
  productContentId: string,
  envContentId: string,
  contentId: string
): Promise<DataRes<ConfigDevtoolResponseDto>> => {
  return await request<ConfigDevtoolResponseDto>(
    `/product/${productContentId}/env/${envContentId}/config/${contentId}/generated/csharp-class`
  );
};

export const getJsonSchema = async (
  productContentId: string,
  envContentId: string,
  contentId: string
): Promise<DataRes<ConfigDevtoolResponseDto>> => {
  return await request<ConfigDevtoolResponseDto>(
    `/product/${productContentId}/env/${envContentId}/config/${contentId}/generated/json-schema`
  );
};

export const downloadJsonZip = async (productContentId: string, envContentId: string, contentId: string) => {
  const response = await requestBlob(
    `/product/${productContentId}/env/${envContentId}/config/${contentId}/generated/json-zip`
  );
  if (response.size > 0 && response.type === "application/zip") {
    return new Blob([response], { type: "application/zip" });
  }
  // expecting only a zip of json.
  return undefined;
};

const WRAPPED_STRING_KEY = "string";
export const deserializeConfigDataByDataType = (config: ConfigDto | Config): string => {
  switch (config.dataType) {
    case ConfigDataType.Json:
      return config.data;

    case ConfigDataType.WrappedString: {
      const incorrectJsonToast = () => toastAdd("Invalid JSON provided in config data", "Error", ToastType.Error);
      let parseResult;
      try {
        parseResult = JSON.parse(config.data);
      } catch (e) {
        incorrectJsonToast();
        throw new Error(`Invalid JSON provided in config data for config ${config.contentId} (rev: ${config.version})`);
      }

      if (typeof parseResult[WRAPPED_STRING_KEY] !== "string") {
        incorrectJsonToast();
        throw new Error(
          `Wrapped string key not found in config data for config ${config.contentId} (rev: ${config.version})`
        );
      }

      return parseResult[WRAPPED_STRING_KEY];
    }
    default:
      throw new Error(`Invalid data type for config ${config.contentId} (rev: ${config.version})`);
  }
};

export const serializeConfigDataByDataType = (config: ConfigDto | Config, data: string): string => {
  switch (config.dataType) {
    case ConfigDataType.Json:
      return data;

    case ConfigDataType.WrappedString: {
      const wrappedString = JSON.stringify({ [WRAPPED_STRING_KEY]: data });
      return wrappedString;
    }
    default:
      throw new Error(`Invalid data type for config ${config.contentId} (rev: ${config.version})`);
  }
};
