export class PushRequestModel {
  public hid = "";
  public name = "";
  public contentId = "";
  public sourceEnvironmentContentId = "";
  public sourceEnvironmentName = "";
  public targetEnvironmentContentId = "";
  public targetEnvironmentName = "";
  public createdBy = "";
  public createdAt = "";
  public lastUpdatedBy = "";
  public lastUpdatedAt = "";
  public configs: PushRequestConfigItem[] = [];
  public statusName = "";
  public status = 0;
  public diffResults = "";
  public approvals: PushRequestApprovals = new PushRequestApprovals();
  public permissions: PushRequestPermissions = new PushRequestPermissions();

  public constructor(model: Partial<PushRequestModel>) {
    Object.assign(this, model);
  }
}

export class PushRequestConfigItem {
  public configContentId = "";
  public description = "";
  public sourceTargetGitDiffTooBig = false;
  public sourceTargetGitDiff = "";
  public configVersion = 0;
  public targetConfigCurrentVersion = 0;
  public hasNewVersion = false;
  public identical = false;
}

export class PushRequestApprovals {
  public approvalsRequired = 0;
  public currentApprovals = 0;
}

export class PushRequestPermissions {
  public userCanPush = false;
  public userCanApprove = false;
  public userCanRevoke = false;
  public userCanClose = false;
}
