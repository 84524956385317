import { DataRes } from "@/models/DataRes";
import { request } from "./BaseService";

const endpoint = "/product";

type GetSyncEnvParams = {
  sourceContentId: string;
  targetContentId: string;
  productContentId: string;
};

export type SyncEnvContentItemEnv = {
  controlPanel: boolean;
  description: string;
  dynamic: boolean;
  hash: string;
  schemaAttached: boolean;
  staticLookupHash: string;
  updateByName: string;
  updatedAt: string;
  version: number;
};

export interface SyncEnvContentItemEnvWithContentId extends Partial<SyncEnvContentItemEnv> {
  contentId: string;
  updatedAt: string;
}

export type SyncEnvContentItem = {
  contentId: string;
  location: {
    status: "equal" | "different" | "not-in-target";
    targetFullPath?: string;
    sourceFullPath: string;
  };
  syncStatus: "equal" | "not-in-target" | "newer" | "older" | "not-in-source";
  source: SyncEnvContentItemEnv;
  target?: SyncEnvContentItemEnv;
};

export type SyncEnvContentComparisonLocation = {
  contentId: string;
  fullPath: string;
  nameFullPath: string;
  type: string;
};

export type SyncEnvContentComparison = {
  contents: SyncEnvContentItem[];
  location: SyncEnvContentComparisonLocation;
};

type SyncEnvInfoItem = {
  contentId: string;
  name: string;
};

type SyncEnvInfoTargetItem = {
  contentId: string;
  name: string;
  isPushProtected: boolean;
};

// Root
export type SyncEnvContentComparisonResponse = {
  contentComparison: SyncEnvContentComparison[];
  info: {
    productContentId: string;
    source: SyncEnvInfoItem;
    target: SyncEnvInfoTargetItem;
  };
};

export const getSyncEnvData = async ({
  sourceContentId,
  targetContentId,
  productContentId,
}: GetSyncEnvParams): Promise<DataRes<SyncEnvContentComparisonResponse>> => {
  const syncEnvData = await request<SyncEnvContentComparisonResponse>(
    `${endpoint}/${productContentId}/sync/${sourceContentId}/${targetContentId}`,
    {
      method: "GET",
    }
  );

  return syncEnvData;
};

export type PushContentItem = {
  contentId: string;
  version: number;
  toTarget: boolean;
  folder: string;
};

type PushSyncEnvParams = {
  productContentId: string;
  contents: PushContentItem[];
  sourceContentId: string;
  targetContentId: string;
};

export type PushSyncEnvResponse = {
  successful: string[];
  errors: string[];
};

export const pushSyncEnvData = async ({
  productContentId,
  contents,
  sourceContentId,
  targetContentId,
}: PushSyncEnvParams): Promise<DataRes<PushSyncEnvResponse>> => {
  return request<PushSyncEnvResponse>(`${endpoint}/${productContentId}/sync/${sourceContentId}/${targetContentId}`, {
    method: "POST",
    body: JSON.stringify({ contents }),
  });
};
